@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  /* --------------------------------------
  *  Colors for CSS variables
  * -------------------------------------- */
  :root {
    --yumyGreen: #118376;
    --yumyGreenHover: #298f80;
    --yumyGreen10: #e7fffc;
    --yumyGreen400: #1aac9b;
    --yumyGreen600: #095c53;
    --yumyLight: #f5f5f5;
    --yumyBlack: #121127;
    --yumyRed: #ef4444;
    --gray: #f5f5f5;
    --grayScale90072: rgba(18, 17, 39, 0.72);
    --grayScale90056: rgba(18, 17, 39, 0.56);
    --grayScale90048: rgba(18, 17, 39, 0.48);
    --grayScale90040: rgba(18, 17, 39, 0.4);
    --grayScale90032: rgba(18, 17, 39, 0.32);
    --grayScale90030: rgba(18, 17, 39, 0.3);
    --grayScale90024: rgba(18, 17, 39, 0.24);
    --grayScale90020: rgba(18, 17, 39, 0.2);
    --grayScale90012: rgba(18, 17, 39, 0.12);
    --grayScale9008: rgba(18, 17, 39, 0.08);
    --grayScale9004: rgba(18, 17, 39, 0.04);
    --grayScale9002: rgba(18, 17, 39, 0.02);
    --white-100: rgba(255, 255, 255, 1);
    --white-56: rgba(255, 255, 255, 0.56);
    --white-48: rgba(255, 255, 255, 0.48);
    --white-40: rgba(255, 255, 255, 0.4);
    --white-24: rgba(255, 255, 255, 0.24);
    --white-12: rgba(255, 255, 255, 0.12);
    --white-8: rgba(255, 255, 255, 0.08);
    --white-4: rgba(255, 255, 255, 0.04);
    --green10: rgba(17, 131, 118, 0.1);
    --yumyGreens-100: #e7f3f1;
    --yumyGreens-200: #f3fdfc;
  }

  /* --------------------------------------
  *  Typography and general styles
  * -------------------------------------- */
  body {
    @apply font-sans;
  }
  h1,
  h2,
  h3,
  h4 {
    @apply leading-140 font-extrabold mb-0;
  }

  h1 {
    @apply text-2xl sm:text-3xl md:text-4xl lg:text-6xl;
  }

  h2 {
    @apply text-xl sm:text-2xl md:text-3xl lg:text-5xl;
  }

  h3 {
    @apply text-lg sm:text-xl md:text-2xl lg:text-3xl;
  }

  h4 {
    @apply text-base sm:text-lg md:text-xl lg:text-2xl;
  }

  input {
    @apply placeholder:text-grayScale900-40 !important;
  }

  input[type="search"] {
    @apply placeholder:text-grayScale900-40 !important;
  }

  textarea {
    @apply placeholder:text-grayScale900-40 !important;
  }
}
