/* --------------------------------------
*  General styling
* -------------------------------------- */
body {
  /* Scrollbar */
  &::-webkit-scrollbar-thumb {
    background-color: var(--yumyGreen);

    &:hover {
      background-color: var(--yumyGreen600);
      cursor: default;
    }
  }
  /* width */
  &::-webkit-scrollbar {
    width: 10px;

    @media (max-width: 575px) {
      width: 5px;
    }
  }
  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  /* For Webkit-based browsers (Chrome, Safari and Opera) */
  .scrollbar-hide::-webkit-scrollbar {
    display: none;
  }

  /* For IE, Edge and Firefox */
  .scrollbar-hide {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
}

.Page {
  a {
    color: var(--yumyGreen);
    text-decoration: underline;

    transition: all 300ms ease-in-out;
    &:hover {
      color: var(--yumyGreen);
      text-decoration: none;
    }
  }

  p {
    margin: 0;
  }

  button:not(.ant-switch) {
    box-shadow: 0 0 0 0 transparent !important;
    border-radius: 999px;
    padding: 6px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    height: auto;

    &.large {
      height: 50px;
      font-weight: 600;
    }

    &.small {
      font-size: 14px;
      font-weight: 500;
    }

    &.action {
      width: 90px;
    }

    &:disabled {
      cursor: not-allowed;
      background-color: var(--yumyGreen);
      color: var(--white-100);
      opacity: 0.5;
    }
  }
}

.YumySider {
  width: 100%;

  /* Scrollbar */
  &::-webkit-scrollbar-thumb {
    background-color: var(--yumyGreen);

    &:hover {
      background-color: var(--yumyGreen600);
      cursor: default;
    }
  }
  /* width */
  &::-webkit-scrollbar {
    width: 10px;

    @media (max-width: 575px) {
      width: 5px;
    }
  }
  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  @media (max-width: 767px) {
    position: fixed;
    height: 100%;
    scrollbar-gutter: stable;
    overflow-y: auto;
    width: 200px;
    z-index: 800;
    transition: transform 300ms ease-in-out;
    transform: translateX(-100%);

    &.menuOpen {
      transform: translateX(0);
    }
  }
}

.YumySiderToggle {
  display: none;

  @media (max-width: 767px) {
    display: flex;
    position: fixed;
    left: 0;
    top: 50%;
    padding: 24px 7px 24px 3px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    color: var(--white-100);
    background-color: var(--yumyGreen);
    z-index: 799;
    cursor: pointer;
    transform: translateX(0) translateY(-50%);
    transition: all 300ms ease-in-out;

    &:hover {
      color: var(--white-56)
    }

    &.menuOpen {
      transform: translateX(200px) translateY(-50%);
    }
  }
}

.SiderDropdown {
  & button {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    justify-content: flex-start !important;
    padding: 8px 8px 8px 32px !important;
    transition: background 0.2s;
    border-radius: 8px !important;

    &.active {
      color: var(--yumyGreen);
    }

    &:hover {
      background: var(--white-4)
    }

    &.add-new {
      justify-content: center !important;
      padding: 8px !important;
      border: 1px solid var(--white-8);
      background: var(--white-4);
      border-radius: 100px !important;

      &:hover {
        background: var(--green10);
      }
    }
  }
}

.DropdownButtonArrow{
  & svg path {
    transition: all 0.2s;
  }

  &.isOpen {
    transform: rotate(180deg);

    & svg path {
      fill: var(--yumyGreen);
      fill-opacity: 1;
    }
  }
}
.DropdownButton {
  &:hover {
    & svg path {
      fill: var(--yumyGreen);
      fill-opacity: 1;
    }
  }
}
/* --------------------------------------
*  Ant Design Custom Styles
* -------------------------------------- */

/* Modal */
.ant-modal-content {
  padding: 24px 16px !important;
  max-height: 700px;
  overflow-y: auto;
  scrollbar-gutter: stable both-edges;
  margin: 8px;

  @media (max-width: 768px) {
    padding: 16px 16px 16px 8px !important;
    max-height: calc(100vh - 200px);
  }

  @media (max-width: 575px) {
    padding: 8px !important;
  }

  /* Scrollbar */
  &::-webkit-scrollbar-thumb {
    background-color: var(--yumyGreen);
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;

    &:hover {
      background-color: var(--yumyGreen600);
    }
  }
  /* width */
  &::-webkit-scrollbar {
    width: 15px;
  
    @media (max-width: 575px) {
      width: 5px;
    }
  }
  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}

/* Product Image */
.product-image {
  & .ant-image {
    width: 30px;
    height: 50px;
    border-radius: 4px;
    flex-shrink: 0;
    overflow: hidden;

    & img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}

/* Checkbox */
.ant-checkbox {
  .ant-checkbox-inner {
    border: 2px solid var(--grayScale90020);
    border-radius: 4px;
    width: 20px;
    height: 20px;

    &::after {
      top: 49%;
      inset-inline-start: 32%;
    }
  }
  &.ant-checkbox-checked {
    .ant-checkbox-inner {
      border: 2px solid var(--yumyGreen);
      background-color: var(--yumyGreen);
    }
  }
  &.ant-checkbox-indeterminate {
    .ant-checkbox-inner {
      border: 2px solid var(--yumyGreen);

      &::after {
        top: 50%;
        inset-inline-start: 50%;
        width: 6px;
        height: 6px;
      }
    }
  }
}

/* Textarea Scrollbar */
textarea {
  /* Scrollbar */
  &::-webkit-scrollbar-thumb {
    background-color: var(--yumyGreen);
    border-top-right-radius: 6px;

    &:hover {
      background-color: var(--yumyGreen600);
      cursor: default;
    }
  }
  /* width */
  &::-webkit-scrollbar {
    width: 10px;
  
    @media (max-width: 575px) {
      width: 5px;
    }
  }
  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-top-right-radius: 10px;
  }
}

/* Placeholder */
.ant-select-selection-placeholder {
  color: var(--grayScale90040) !important;
}

/* Tag */
.ant-tag {
  margin-inline-end: 0px;
  padding-inline: 0px;
  display: flex;
  padding: 0px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  border: 0;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: 180%;

  &.draft {
    color: var(--yumyBlack);
    background: var(--grayScale9004);
  }
  &.active {
    background: var(--yumyGreens-100) !important;
    color: var(--yumyGreen) !important;
  }
}

/* Badge */
.ant-badge {
  background: var(--yumyGreen400);
  padding: 2px;
  border-radius: 100%;

  & .ant-badge-count {
    box-shadow: none;
  }
}

/* Upload */
.ant-upload-wrapper {
  height: 100%;
  overflow: hidden;

  @media (max-width: 700px) {
    width: 160px;
    height: 230px;
  }

  .ant-upload {
    margin-inline-end: 0px !important;
    margin-bottom: 0px !important;
    width: 100% !important;
    height: 100% !important;
  }
  .ant-upload-list-picture-card {
    width: 100%;
    height: 100%;
    
    .ant-upload-list-item-container {
      width: 100% !important;
      height: 100% !important;
      margin-block: 0 !important;
      margin-inline: 0 !important;

      .ant-upload-list-item {
        padding: 0 !important;
        overflow: hidden;

        &:before {
          width: 100% !important;
          height: 100% !important;
          border-radius: 8px !important;
        }

        & img {
          object-fit: cover !important;
        }

        & .ant-upload-list-item-actions {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 8px;
        }

        & .ant-upload-list-item-thumbnail {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        & .ant-upload-list-item-progress {
          width: 100%;

          & .ant-progress-bg {
            height: 5px !important;
            background: var(--yumyGreen) !important;
          }
        }
      }
    }
  }
}

/* Select */
.ant-select-item {
  &.ant-select-item-option.ant-select-item-option-selected {
    background: var(--yumyGreens-100) !important;
    color: var(--yumyGreen) !important;
    font-weight: 400 !important;
  }
}

  /* ------------------------------------------------------
    Form Content
  ------------------------------------------------------ */
.FormContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  transition: margin-top 300ms ease-in-out;

  margin-top: 15px;

  &.modal {
    margin-top: 0;
  }

  &:not(.modal) {
    max-width: 455px;
    gap: 24px;
  }

  /* ------------------------------------------------------
    Remove styling from antd components
  ------------------------------------------------------ */

  input,
  span,
  input.ant-input-status-error,
  .ant-input-number-status-error,
  .ant-select-status-error .ant-select-selector,
  textarea.ant-input-status-error,
  input#password,
  .ant-picker,
  .ant-picker-input {
    border: 0 !important;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000000s ease-in-out 0s;
  }

  .ant-form-item {
    margin-bottom: 0 !important;
  }

  .ant-input-affix-wrapper input,
  .ant-select .ant-select-selector input,
  .ant-select .ant-select-selector input:hover,
  .ant-select .ant-select-selector input:focus,
  .ant-select .ant-select-selector input:active,
  .ant-select .ant-select-selector input:focus-within,
  input#password,
  .ant-picker input,
  .ant-picker-input {
    height: auto;
    box-shadow: none !important;
  }

  /* ------------------------------------------------------
    Text and color
  ------------------------------------------------------ */

  input {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 180%;
    color: var(--yumyBlack);
    transition: color 300ms ease-in-out;
  }

  .ant-select-item-option-selected {
    color: var(--yumyGreen) !important;
  }

  /* ------------------------------------------------------
    Flex, height, padding, margin
  ------------------------------------------------------ */

  & form {
    flex-direction: column;
    gap: 32px;

    @media (max-width: 767px) {
      gap: 16px;
    }
  }

  & form,
  & input,
  & textarea,
  .ant-select,
  .ant-select .ant-select-selector,
  .ant-select-selector input,
  .ant-picker {
    display: flex;
  }

  .ant-select-selector input:focus-within,
  .ant-select-selector input:focus,
  .ant-select-selector input:hover,
  .ant-select-selector input:active,
  .ant-select-single,
  .ant-picker {
    height: 100% !important;
  }

  & input,
  & textarea,
  .ant-select,
  .ant-select .ant-select-selector,
  .ant-select-selector input {
    align-items: center;
  }

  & input,
  & textarea,
  .ant-select .ant-select-selector,
  .ant-picker {
    padding: 6px 16px 5px 16px;
    border-radius: 8px;
  }
  .ant-select-selector input {
    padding: 6px 16px 5px 5px !important;
  }

  & input,
  & .ant-input-affix-wrapper,
  .ant-select .ant-select-selector,
  .ant-picker {
    height: 52px;
  }

  &.modal input,
  &.modal .ant-input-affix-wrapper,
  &.modal .ant-select .ant-select-selector,
  &.modal .ant-picker {
    height: 40px;
  }

  .ant-input-number {
    width: 100%;
  }

  /* ------------------------------------------------------
    Box Shadow
  ------------------------------------------------------ */

  & input,
  & .ant-input-affix-wrapper,
  & .ant-select .ant-select-selector,
  & textarea,
  .ant-picker {
    transition: box-shadow 300ms ease-in-out;
    box-shadow: 0 0 0 1px var(--grayScale9008);
    /* WebKit */
    -webkit-appearance: none !important;

    /* Mozilla */
    -moz-appearance: none;

    /* Opera */
    -o-appearance: none;

    /* Internet Explorer */
    -ms-appearance: none;

    /* CSS3 */
    appearance: none;
  }

  & input:hover,
  & input:focus,
  & input:active,
  & input:focus-within,
  & .ant-input-affix-wrapper:hover,
  & .ant-input-affix-wrapper:focus-within,
  & .ant-select .ant-select-selector:hover,
  & .ant-select .ant-select-selector:focus,
  & .ant-select .ant-select-selector:active,
  & .ant-select .ant-select-selector:focus-within,
  & textarea:hover,
  & textarea:focus,
  & textarea:active,
  & textarea:focus-within,
  .ant-picker:hover,
  .ant-picker:focus,
  .ant-picker:active,
  .ant-picker:focus-within {
    /* WebKit */
    -webkit-appearance: none !important;

    /* Mozilla */
    -moz-appearance: none;

    /* Opera */
    -o-appearance: none;

    /* Internet Explorer */
    -ms-appearance: none;

    /* CSS3 */
    appearance: none;
    box-shadow: 0 0 0 2px var(--yumyGreen) !important;
  }

  .ant-picker-suffix {
    color: var(--yumyGreen) !important;
  }

  .ant-picker input {
    box-shadow: none !important;
  }
}

.Label {
  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      color: var(--yumyGreen);
    }
  }
}

.SwitchText {
  a {
    color: var(--yumyGreen);
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}


  /* ------------------------------------------------------
    Menu Settings
  ------------------------------------------------------ */
.MenuSettingsButtonsWrapper {
  & #menuSettingsSave,
  & #menuSettingsCancel,
  & #menuSettingsClose {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 24px;
    border-radius: 999px;
    border: none;
    height: 40px;

    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;

    box-shadow: none;
  }

  & #menuSettingsSave {
    width: 160px;
    background: var(--yumyGreens-100);
    color: var(--yumyGreen);

    &:disabled {
      opacity: 0.3;
    }

    @media (max-width: 575px) {
      width: 50%;
    }
  }

  & #menuSettingsCancel,
  & #menuSettingsClose {
    width: 100px;
    background: var(--grayScale9004);

    @media (max-width: 575px) {
      width: 50%;
    }
  }
}

.MenuSettingsButtonWrap {
  & button {
    background: var(--grayScale9004);
    border-radius: 999px;
    border: 1px solid var(--grayScale9004);
  }
}

/* --------------------------------------
*  Print KDS styles
* -------------------------------------- */

@media print {
  body {
    visibility: hidden;
    position: relative;
  }
  .printable-kds-item {
    visibility: visible;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    & * {
      color: black !important;
    }
    
    .kds-item {
      max-width: 500px;
      margin: 0 auto;
      margin-top: 40px;
      border: 0;
  
      .ant-btn {
        display: none;
      }
  
      .kds-item-header,
      .kds-item-footer {
        background: transparent;
      }
    }
  }
}